<template>
  <div>

    <div style="display: flex;flex-direction: row;justify-content: center;">
      <p style="font-size: 2.2em;font-weight: bold;margin-top: 10px">{{ title }}</p>
    </div>

    <div style="display: flex;flex-direction: row;justify-content: flex-start;align-items: center">
      <el-button v-on:click="openAddWeekForm" type="primary" style="max-height: 50px;margin: 8px">新建月报表</el-button>
      <el-button v-on:click="openDeleteWeekForm" type="primary" style="max-height: 50px;margin: 8px">删除月报表</el-button>
      <el-select v-model="showWeekNum" placeholder="请选择要查看的月报表" @change="selectWeekForm">
        <el-option
          v-for="item in weekNums"
          :key="item.msg"
          :value="item.msg"
          :label="item.msg"
        >
        </el-option>
      </el-select>
      <el-button @click="exportTable"    type="primary" style="margin-left: 10px">导出报表</el-button>
    </div>

    <el-table
      id="myTable"
      :data="weekForm"
      border
      style="width: 100%">
      <el-table-column
        prop="centerStation"
        label="中心站"
        fixed
        width="180">
      </el-table-column>
      <el-table-column
        prop="machineName"
        label="换热站"
        fixed
        width="180">
      </el-table-column>
      <el-table-column
        prop="createYear"
        label="建设时间"
        width="180">
      </el-table-column>
      <el-table-column
        prop="area"
        width="120"
        label="面积">
      </el-table-column>
<!--      <el-table-column-->
<!--        prop="residentNum"-->
<!--        width="100"-->
<!--        label="户数">-->
<!--      </el-table-column>-->
      <el-table-column
        width="100"
        prop="openValve"
        label="开阀率">
      </el-table-column>
      <el-table-column
        width="120"
        prop="proportion"
        label="所占比例">
      </el-table-column>
      <el-table-column
        width="120"
        label="水">

        <el-table-column
          width="120"
          prop="planTargetWater"
          label="计划指标">
        </el-table-column>

        <el-table-column
          width="120"
          prop="expendWater"
          label="实耗量">
        </el-table-column>


        <el-table-column
          width="120"
          prop="residueWater"
          label="当月结余">
        </el-table-column>
        <el-table-column
          width="120"
          prop="targetWater"
          label="指标">
        </el-table-column>
        <el-table-column
          width="120"
          prop="unitWater"
          label="单耗">
        </el-table-column>
      </el-table-column>

      <el-table-column
        width="120"
        label="电">

        <el-table-column
          width="120"
          prop="planTargetPower"
          label="计划指标">
        </el-table-column>

        <el-table-column
          width="120"
          prop="expendPower"
          label="实耗量">
        </el-table-column>

        <el-table-column
          width="120"
          prop="residuePower"
          label="当月结余">
        </el-table-column>
        <el-table-column
          width="120"
          prop="targetPower"
          label="指标">
        </el-table-column>
        <el-table-column
          width="120"
          prop="unitPower"
          label="单耗">
        </el-table-column>
      </el-table-column>

      <el-table-column
        width="120"
        label="热">

        <el-table-column
          width="120"
          prop="planTargetHeat"
          label="计划指标">
        </el-table-column>

        <el-table-column
          width="120"
          prop="expendHeat"
          label="实耗量">
        </el-table-column>


        <el-table-column
          width="120"
          prop="residueHeat"
          label="当月结余">
        </el-table-column>
        <el-table-column
          width="120"
          prop="targetHeat"
          label="指标">
        </el-table-column>
        <el-table-column
          width="120"
          prop="unitHeat"
          label="单耗">
        </el-table-column>
      </el-table-column>

    </el-table>

    <el-dialog title="新增月报表" :visible.sync="dialogFormVisible">
      <el-form :model="weekFormAddDTO">
        <el-form-item label="日期选择" >
          <el-date-picker
            v-model="weekFormAddDTO.startTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="起始日期">
          </el-date-picker>
          <el-date-picker
            style="margin-left: 20px"
            v-model="weekFormAddDTO.stopTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="createWeekForm">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除周报表" :visible.sync="dialogFormVisibledelete">
      <el-form :model="deleteWeekNum">
        <el-form-item label="删除第几周:" >
          <el-select v-model="deleteWeekNum" placeholder="请选择" >
            <el-option
              v-for="item in weekNums"
              :key="item"
              :label="item.msg"
              :value="item.msg"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibledelete = false">取 消</el-button>
        <el-button type="primary" @click="deleteWeekForm">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import clientUtils from "@/js/clientUtils";
import {exportExcelByTableId} from "@/js/excel";

export default {
  name: "MonthForm",
  data() {
    return {
      title: "",
      options: [{
        value: '水',
        label: '水'
      }, {
        value: '电',
        label: '电'
      }, {
        value: '热',
        label: '热'
      }],
      value: '',
      dialogTableVisible: false,
      dialogFormVisible: false,
      dialogFormVisibledelete: false,
      weekFormAddDTO: {
        startTime: "",
        stopTime: "",
        weekNum: ""
      },
      weekNums: [

      ],
      deleteWeekNum: {},
      weekForm: [],
      showWeekNum: {
        startTime: "",
        stopTime: "",
      },
      showWeek: false,

    }
  },
  methods: {
    exportTable:function (){
      exportExcelByTableId("myTable","月报表")
    },
    initTitle: function () {    //拼接title的方法，在挂载期调用
      this.userInfo = clientUtils.getLocalUserInfo();
      let myDate = new Date();
      let year = myDate.getFullYear()
      let month = myDate.getMonth()+1

      if (month >= 11){
        this.title = this.userInfo.allowCompanys + year + "~" + (year + 1) + "年度《服务部》能耗统计表"
      }else {
        this.title = this.userInfo.allowCompanys + (year-1) + "~" + (year) + "年度《服务部》能耗统计表"
      }
    },

    //新建月报表对象
    createWeekForm: function () {
      const that = this;
      let updatejosn = JSON.stringify(this.weekFormAddDTO);
      clientUtils.post("/system/monthPlan/save", updatejosn, function (res) {
        if (res.data != null) {
          // 编辑用户信息成功
          that.back();
          that.getMonthNums()
          that.$message({
            message: '数据更新成功',
            type: 'success'
          });
          that.selectWeekForm()
        } else {
          clientUtils.unknownErrorAlert(that, res.data.msg)
        }
      })

    },

    back: function () {
      this.dialogFormVisible = false
    },

    openAddWeekForm: function () {
      this.dialogFormVisible = true
    },

    openDeleteWeekForm: function () {
      this.getMonthNums()
      this.dialogFormVisibledelete = true
    },

    //获取周报表一共有几周
    getMonthNums: function () {
      const that = this
      clientUtils.get("/system/monthPlan/selectTime", function (res) {
        if (res.data != null) {
          console.log("数据：" + res.data.data)
          that.weekNums = res.data.data
        }
      })
    },

    //删除周报表对象
    deleteWeekForm: function () {
      this.weekNums.forEach(item=>{
        if (item.msg===this.deleteWeekNum){
          const that = this
          let updatejosn = JSON.stringify(item.selectTime);
          clientUtils.post("/system/monthPlan/deleteMonthForm", updatejosn, function (res) {
            if (res.data != null) {
              that.dialogFormVisibledelete = false
              that.getMonthNums()
              that.$message({
                message: '数据删除成功',
                type: 'success'
              });
            } else {
              clientUtils.unknownErrorAlert(that, res.data.msg)
            }
          })
        }
      })
    },

    //查询周报表对象
    selectWeekForm: function () {
      this.weekNums.forEach(item=>{
        if (item.msg===this.showWeekNum){
          const that = this
          let updatejosn = JSON.stringify(item.selectTime);
          clientUtils.post("/system/monthPlan/selectMonthFormByTime", updatejosn, function (res) {
            if (res.data != null) {
              that.back();
              that.weekForm = res.data.data
              that.$message({
                message: '数据更新成功',
                type: 'success'
              });
            } else {
              clientUtils.unknownErrorAlert(that, res.data.msg)
            }
          })
        }
      })
    }
  },
  mounted() {
    this.getMonthNums()
  },
  created() {
    this.value = "水"
    sessionStorage.setItem("dayFromType", this.value)
    this.initTitle()
  }
}
</script>

<style scoped>

</style>
